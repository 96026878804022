const db = {
	stadium: [
		{
			date: 'Update',
			img: require('./assets/images/StadiumNoBug.jpg'),
			title: 'StadiumRoadNoBug',
			author: 'SuperCoby',
			desc: 'version 1.0.4',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1tDg2ofx8mxCHcHomL-KzXqbHj9MnPfy2',
		},
		{
			date: '1m ago',
			img: require('./assets/images/Fx.png'),
			title: 'Fx',
			author: 'SuperCoby',
			desc: '44 Blocks',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1nlmE5IrQmUUMQpCfZufvmz3IhwH_7CbQ',
		},
		{
			date: '1m ago',
			img: require('./assets/images/PlatformTech20.jpg'),
			title: 'PlatformTech20',
			author: 'SuperCoby',
			desc: '16 blocks',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1jFXZiicBwqJX-Hhp12Xr26P1_Qx1crGt',
		},
		{
			date: '2m ago',
			img: require('./assets/images/PlatformGrass20.jpg'),
			title: 'PlatformGrass20',
			author: 'SuperCoby',
			desc: '98 blocks',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1HA-S5zd7eXhn7yJ_8avLhMU3H4BHV6HM',
		},
		{
			date: '2m ago',
			img: require('./assets/images/PlatformTrackWall.jpg'),
			title: 'PlatformTrackWall',
			author: 'SuperCoby',
			desc: '5 blocks',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1J5vD5LYZ5zxaojUN24QAbAXuBaLD1Umj',
		},
		{
			date: '2m ago',
			img: require('./assets/images/PlatformRoad20.jpg'),
			title: 'PlatformRoad20',
			author: 'SuperCoby',
			desc: '81 blocks',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1FWhsn2cgxiF3rr-AR1PayD1NWzpwrupD',
		},
		{
			date: '2m ago',
			img: require('./assets/images/ESL.jpg'),
			title: 'Block ESL',
			author: 'SuperCoby',
			desc: '177 blocks',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1slHh6YJHWWMN0-JOmDT4n2Fdo9iQi9jP',
		},
		{
			date: '2m ago',
			img: require('./assets/images/StadiumRoad20.jpg'),
			title: 'StadiumRoad20',
			author: 'SuperCoby',
			desc: '38 blocks',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1Jfl1Ntm-NoQFQw5DX6unUqgIXTQzpAv4',
		},
		{
			date: '2m ago',
			img: require('./assets/images/AutoRot.jpg'),
			title: 'AutoRot',
			author: 'SuperCoby',
			desc: 'CP/Start/Finish/Multi',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1vydR3tlxN5fCmX9fVS_2aSOnzLn0LIpO',
		},
		{
			date: '2m ago',
			img: require('./assets/images/StadiumRoadBeam.jpg'),
			title: 'StadiumRoadBeam',
			author: 'SuperCoby',
			desc: 'Pack StadiumRoadBeam',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1rteuyOE3V_P0o0blZ7fU6I-6viK5V2IA',
		},
		{
			date: '2m ago',
			img: require('./assets/images/StadiumTrench.jpg'),
			title: 'StadiumTrench',
			author: 'SuperCoby',
			desc: 'Pack StadiumTrench',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1UN8KhaxZqOqk5BsWcPaavrAU7uQxb-D4',
		},
		{
			date: '2m ago',
			img: require('./assets/images/StadiumRoadBump.jpg'),
			title: 'StadiumRoadBump',
			author: 'SuperCoby',
			desc: 'Pack StadiumRoadBump',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1qLZIxhtfhbxCLCmNTkR2-hy1zGh79sMs',
		},
		{
			date: '2m ago',
			img: require('./assets/images/PlatformFlat.jpg'),
			title: 'PlatformFlat',
			author: 'SuperCoby',
			desc: 'Pack PlatformFlat ESL',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1TCaidcJDjtXbamuAFgnTsVmWgx2s8OUc',
		},
		{
			date: '2m ago',
			img: require('./assets/images/BoostRedYellow.jpg'),
			title: 'BoostRedYellow',
			author: 'SuperCoby',
			desc: 'RedBoost + YellowBoost',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1xePsb3x80Mfl5L_GcZ_IoWLjNxVxs3wF',
		},
		{
			date: '2m ago',
			img: require('./assets/images/RandomPlatform.jpg'),
			title: 'RandomPlatform',
			author: 'SuperCoby',
			desc: '7 blocks',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1_iN5AuZx4JoH8whiEOFx2ApdsGY9Y5tJ',
		},
		{
			date: '2m ago',
			img: require('./assets/images/PlatformGrid.jpg'),
			title: 'PlatformGrid',
			author: 'SuperCoby',
			desc: '11 blocks',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1BHo9-RPbhWJJ6tkj3Yn_rORyx4w_PiLj',
		},
		{
			date: '2m ago',
			img: require('./assets/images/Dirt1.jpg'),
			title: 'Dirt1',
			author: 'SuperCoby',
			desc: 'Pack',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1D4XLs2ejgzK5R-lGpgDHmNL9AI_lo6vH',
		},
		{
			date: '2m ago',
			img: require('./assets/images/Dirt2.jpg'),
			title: 'Dirt2',
			author: 'SuperCoby',
			desc: 'Pack',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1xtu_5cCUDh6aAbeBNv6F1hdTOeK7a0H4',
		},
		{
			date: '2m ago',
			img: require('./assets/images/Dirt3.jpg'),
			title: 'Dirt3',
			author: 'SuperCoby',
			desc: 'Pack',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1udFmChdqoXost8fx7n5ib-UwqZTuh82X',
		},
	],
	united: [
		{
			date: '1d ago',
			img: require('./assets/images/TM_Speed.jpg'),
			title: 'TM_Speed',
			author: 'Florenzius (convert by SuperCoby)',
			desc: 'version 1.0.1',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1eIIvY2sZKBIwZzg2o0FNqlCQdp3e87EP',
		},
		{
			date: '1d ago',
			img: require('./assets/images/Rally.jpg'),
			title: 'Rally Essentials',
			author: 'Sebik1992',
			desc: 'version 1.0.1',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1FDl5APCZd2yTIR3IvS8NUGoF5H8FsqJB',
		},
		{
			date: '1d ago',
			img: require('./assets/images/Snow.jpg'),
			title: 'Snow Essentials',
			author: 'Sebik1992',
			desc: 'version 1.0.0',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1UBR4T5Qcsq7w0GKd4aC3J5XzEd-lbfxu',
		},
		{
			date: '1d ago',
			img: require('./assets/images/Coast_Ketrab.jpg'),
			title: 'Coast Pack',
			author: 'Ketrab',
			desc: 'version 1.0.0',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1x2UMswbKHyS4g_tSYen2nXDL8CvDGUev',
		},	
	],
	others: [],
	tools: [
		{
			date: '2m ago',
			img: require('./assets/images/NoImage.jpg'),
			title: 'gbxtmtptools',
			author: '',
			desc: 'fixed the gbx stuff for tmnf',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1MOX3eHTpweHbpn1h3YGabUMfcP22jOi6',
		},
		{
			date: '2m ago',
			img: require('./assets/images/TMUnlimiterTools.jpg'),
			title: 'TMUnlimiterTools',
			author: 'SuperCoby',
			desc: 'addon Blender 4.0+',
			downloadLink: 'https://github.com/SuperCoby/TM_Unlimiter_Tool-2.0',
		},
		{
			date: '2m ago',
			img: require('./assets/images/TmPakTool.jpg'),
			title: 'TmPakTool',
			author: '',
			desc: 'Utility for viewing and editing pak archives. Works with both Forever and United',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1vCICO5eSHt2P9p4mlBB8Fek2oO8tefV7',
		},
		{
			date: '2m ago',
			img: require('./assets/images/trackstudio.jpg'),
			title: 'TrackStudio',
			author: '',
			desc: 'utility for visual block mixing.',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1UEvoddjgC1adssIrliHxl7xqrmo9FgcW',
		},
		{
			date: '2m ago',
			img: require('./assets/images/ChallengeEdit.jpg'),
			title: 'ChallengeEdit',
			author: '',
			desc: '-',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1m9FNe2e6EKjq9sGuRo7Z0KjMeKb1lwv4',
		},
		{
			date: '2m ago',
			img: require('./assets/images/NoImage.jpg'),
			title: 'ReplayToChallenge',
			author: '',
			desc: 'Extracts the Challenge from a given Replay file',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1TxgVq0DN23-PEzARo7-O3LD-n1qfD4tr',
		},
		{
			date: '2m ago',
			img: require('./assets/images/NoImage.jpg'),
			title: 'Tm1Demux',
			author: '',
			desc: 'Utility for extracting music in ogg format from mux container',
			downloadLink: 'https://drive.google.com/uc?export=download&id=13cHi3NVswMd0xDdy_uS7GIErEtVVzAay',
		},
		{
			date: '2m ago',
			img: require('./assets/images/NoImage.jpg'),
			title: 'Tm1outpass',
			author: '',
			desc: 'to remove the password on your own track',
			downloadLink: 'https://drive.google.com/uc?export=download&id=1UCGjvwZ50HU2Gt77QdR7H4bSsqOmwYpN',
		},
	],
	unlimiter20: {
		button1: { text: 'Download', link: 'https://unlimiter.net/download' },
		button2: { text: 'Blender Plugin 4.0', link: 'https://github.com/tomek0055/tmunlimiter_blender_plugin' },
		button3: { text: 'Documentation', link: 'https://github.com/tomek0055/TMUnlimiter/wiki' },
		scripts: [
			{
				date: '2m ago',
				title: 'ObjectInfo',
				author: 'Facecat',
				desc: 'Object info in the consol ',
				downloadLink: 'https://drive.google.com/uc?export=download&id=1c9dFwcKv3yVjv2VHv1z0D9j55iZrPXcX',
			},
			{
				date: '2m ago',
				title: 'MouseSteer',
				author: 'Spiderfffun',
				desc: 'control your car with the mouse',
				downloadLink: 'https://drive.google.com/uc?export=download&id=1hId1b8hAB-lDF5uoGpjR01I7gBcpb3jf',
			},
			{
				date: '2m ago',
				title: 'MagnetGround',
				author: 'Spiderfffun',
				desc: 'work only on the ground',
				downloadLink: 'https://drive.google.com/uc?export=download&id=10WFa1xOGkRcHqOtIj-gF5qS9B1sK3wur',
			},
			{
				date: '2m ago',
				title: 'Heightometer',
				author: 'ByroBuff',
				desc: '-',
				downloadLink: '',
			},
			{
				date: '2m ago',
				title: 'DrunkCamera',
				author: 'Spiderfffun',
				desc: '-',
				downloadLink: 'https://drive.google.com/uc?export=download&id=1GQTZq0fmktm7-RgFhCh7Ed1llhOoG4F8',
			},

			{
				date: '2m ago',
				title: 'Jump1',
				author: 'Spiderfffun',
				desc: 'ActionKey 2, customize the 1.5 value in the middle to get different jump values.',
				downloadLink: 'https://drive.google.com/uc?export=download&id=1SxKub9OZwP3mJfnTBdERTEtDDlUrqU_q',
			},
			{
				date: '2m ago',
				title: 'Jump2',
				author: 'Spiderfffun',
				desc: 'jump script, via wheel height instead of applying force',
				downloadLink: 'https://drive.google.com/uc?export=download&id=1fiuQf667tVlOOgGE1Cv_kfKI_rTSv4GD',
			},
		],
	},
	unlimiter13: {
		button1: { text: 'Download', link: 'https://drive.google.com/uc?export=download&id=1a6br0DghxNWOeY8WCSrGJr55I5kE7e2R' },
		button2: { text: 'Blender Plugin 3.1+', link: 'rhttps://github.com/SuperCoby/TM_Unlimiter_Tool' },
		button3: { text: 'Documentation', link: 'https://drive.google.com/uc?export=download&id=14P3J357hYXs9GY0H1LSdOtqn0KqtBB4s' },
		scripts: [
			{
				date: '2m ago',
				title: 'blockmove',
				author: 'Author',
				desc: 'Description',
				downloadLink: 'https://drive.google.com/uc?export=download&id=1URoEfB6GJQ0qadDqdUUJZ5g6XK8JAdef',
			},
			{
				date: '2m ago',
				title: 'Bump',
				author: 'Author',
				desc: 'Description',
				downloadLink: 'https://drive.google.com/uc?export=download&id=1BBJqSytIxXOQkJsznBh4aERUfbI4wkcq',
			},
			{
				date: '2m ago',
				title: 'CpCounter',
				author: 'Lixx',
				desc: 'Description',
				downloadLink: 'https://drive.google.com/uc?export=download&id=1ixF9OB1Ksz3B6UYhZFfT17czv1kQA3tP',
			},
			{
				date: '2m ago',
				title: 'DynamicBlocks',
				author: 'Author',
				desc: 'Description',
				downloadLink: 'https://drive.google.com/uc?export=download&id=1e_D7Z8LExzYILB8e1Mcgx2zSIthO8mKz',
			},
			{
				date: '2m ago',
				title: 'Fly',
				author: 'Lixx',
				desc: 'Description',
				downloadLink: 'https://drive.google.com/uc?export=download&id=1ah7BSgBz-6h_AYtpazTNgj_RbuYlq0l_',
			},

			{
				date: '2m ago',
				title: 'Fps',
				author: 'Author',
				desc: 'Description',
				downloadLink: 'https://drive.google.com/uc?export=download&id=1egxM723yjaST4sd2KoBl2qjy3kkx8LB6',
			},
			{
				date: '2m ago',
				title: 'PositionBlock',
				author: 'Author',
				desc: 'Description',
				downloadLink: 'https://drive.google.com/uc?export=download&id=1nUcWEcSNXhv56zXjv5fF9GQYJYgiEIai',
			},
			{
				date: '2m ago',
				title: 'PositionXYZ',
				author: 'Author',
				desc: 'Description',
				downloadLink: 'https://drive.google.com/uc?export=download&id=1l2yRR2_McMEeiogpTcSZHkaIaEEHOVdE',
			},
			{
				date: '2m ago',
				title: 'Redlight-greenlight',
				author: 'Author',
				desc: 'Description',
				downloadLink: 'https://drive.google.com/uc?export=download&id=1g6eg-sivv5JmTp_324eSx6QCMQj4K8xL',
			},
			{
				date: '2m ago',
				title: 'Scale Vehicle',
				author: 'Author',
				desc: 'Description',
				downloadLink: 'https://drive.google.com/uc?export=download&id=19QrsWB6qksutu3sNJhJVXqxvZWsPiLKz',
			},
			{
				date: '2m ago',
				title: 'Teleport',
				author: 'Author',
				desc: 'Description',
				downloadLink: 'https://drive.google.com/uc?export=download&id=1d-fAkncaKVKB_uv6pwuJXn9Z9wXEOhxW',
			},
			{
				date: '2m ago',
				title: 'GpsClick',
				author: 'Author',
				desc: 'Description',
				downloadLink: 'https://drive.google.com/uc?export=download&id=1TCCnNVpK_4y0LAYGSDQZJgo-dBlVf83w',
			},
		],
	},
	unlimiter12: {
		button1: { text: 'Download', link: 'https://drive.google.com/uc?export=download&id=1VcKKhZLXU2ouGdhfLbF5foPhUrWx35dr' },
		button2: { text: 'Documentation', link: 'https://drive.google.com/uc?export=download&id=1wbFQwaNyh0bE2XWyAicJwybmeoj02Tzj' },
		scripts: [
			{
				date: '2m ago',
				title: 'YggdrasilHovermain',
				author: 'Author',
				desc: 'Description',
				downloadLink: 'https://drive.google.com/uc?export=download&id=1S0QDpaKXl9-clKI858Q9S21Di0xAecc0',
			},

			{
				date: '2m ago',
				title: 'YggdrasilHoverupdater',
				author: 'Author',
				desc: 'Description',
				downloadLink: 'https://drive.google.com/uc?export=download&id=1z2Ue9DFMnUI_qf4qu0drCQnS-Sst5I1M',
			},
		],
	},
};

export default db;
